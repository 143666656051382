import Sidebar from './Sidebar';
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Autocomplete, Box, Collapse, IconButton, Skeleton, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useCallback, useRef } from 'react'
import Cookies from 'js-cookie';
import { uniqueArrayOfObject } from '../helper/object';
import moment from "moment-timezone";
import Dropdown from "react-bootstrap/Dropdown";
import { MdCalendarMonth, MdOutlineDangerous, MdOutlineVerified } from 'react-icons/md';
import { DateRange } from "react-date-range";
import warningIcon from "../asset/warning.png"
import useDebounce from '../helper/useDebounce';
import { get_patient, get_patient_with_contacts, getPatientAndContactCounts } from '../redux/slice/patients';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { PiArrowClockwiseBold } from 'react-icons/pi';
import { create_brodcast_message } from '../redux/slice/message_slice';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from 'react-toastify';
import Chip from '@mui/material/Chip';
import { Select, MenuItem } from '@mui/material';
import { AiOutlineUserAdd } from "react-icons/ai";
import { AiOutlineUserDelete } from "react-icons/ai";

import { IoIosWarning, IoMdClose } from "react-icons/io";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { get_relationship } from '../redux/slice/relationship';
import { get_contacttype } from '../redux/slice/contact_type';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import { get_facility } from '../redux/slice/facility';
import "../module_css/mass_page.css"
import { useMediaQuery } from '@mui/material';
import { IoCloseSharp } from 'react-icons/io5';
import { get_units } from '../redux/slice/unit';
import { Modal } from 'react-bootstrap';

const columns = [
    1, 2, 3, 4, 5, 6
]

const filterData = (data) => {
    const vals = data ? data?.map((it) => {
        return {
            _id: it._id,
            value: it?.name
        }
    }) : []
    return [{ _id: "null", value: "* No Facility" }, ...vals];
}
// const caregiverfilter = (data) => {
//     return data?.map((it) => {
//         return {
//             _id: it._id,
//             value: `${it?.lastName} ${it?.firstName}`
//         }
//     })
// }

const Mass = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState({});
    const handleRowClick = (rowId) => {
        if (!rowId) return;
        setOpen((prev) => ({ ...prev, [rowId]: !prev[rowId] }));
    };

    const {
        patientsWithContacts,
        loading,
        totalPatientsWithContacts,
        totalContacts,
        infiniteLoading,
        countLoading
    } = useSelector((state) => state.patient);
    const {
        msg_loading,
    } = useSelector((state) => state.message);
    const [active, setActive] = useState(false);
    const [show, setShow] = useState(false);
    const [showconfirm, setShowconfirm] = useState(false);
    const [showlimit, setShowlimit] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [dropdownopen, setDropdownopen] = useState(false);
    const [isDropdown, setIsDropdown] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();
    const [searchFilter, setSearchFilter] = useState('')
    const shift = useSelector((state) => state.shift);
    const relation = useSelector((state) => state.relationship?.data?.data)
    const unit = useSelector((state) => state?.unit?.data?.data)
    const contact = useSelector((state) => state.contact?.data?.data)
    const profile = useSelector((state) => state.profile);
    const facility = useSelector(state => state.facility)
    const dispatch = useDispatch()
    const [broadcastMessage, setBroadcastmessage] = useState("");
    const [selectedRelations, setSelectedRelations] = useState([]);
    const relationsOptions = relation?.map((item) => ({
        _id: item._id,
        value: item.name,
    }));
    const unitOptions = unit?.map((item) => ({
        _id: item._id,
        value: item.name,
    }));

    const [selectedContact, setSelectedcontact] = useState([])
    const contactOption = contact?.map((item) => ({
        _id: item._id,
        value: item.name,

    }))
    const [msgLoading, setMsgLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width: 768px)');
    const token = Cookies.get('token');
    const [searchQuery, setSearchQuery] = useState('');
    const [contactName, setContactName] = useState('');
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [textResponse, setTextResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [phone, setPhone] = useState('');
    const debouncedSearch = useDebounce(searchQuery, 500);
    const debouncedContactName = useDebounce(contactName, 500);
    const debouncedRelationship = useDebounce(selectedRelations, 500);
    const debouncedPhone = useDebounce(phone, 500);
    const listInit = {
        _id: '',
        value: ''
    }
    const [facilityList, setFacilityList] = useState([])
    const [hiddenContacts, setHiddenContacts] = useState({});


    const toggleHiddenContacts = (rowId) => {
        setHiddenContacts((prev) => ({
            ...prev,
            [rowId]: !prev[rowId],
        }));
    };


    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);
    // filter agency filter
    function sortArrayByValue(array) {
        array?.sort((a, b) => {
            const valueA = a.value?.toLowerCase();
            const valueB = b.value?.toLowerCase();
            if (valueA < valueB) return -1;
            if (valueA > valueB) return 1;
            return 0;
        });
        return array;
    }

    useEffect(() => {
        dispatch(get_facility({ token }))
    }, [profile])

    useEffect(() => {
        dispatch(get_relationship({ token }))
        dispatch(get_contacttype({ token }))
        dispatch(get_units({ token }))
    }, [])

    useEffect(() => {
        if (profile?.data?.roles[0]?.name == 'admin') {
            const list = filterData(facility?.facility_data)
            setFacilityList(sortArrayByValue(list))
        }
        else if (profile?.data?.roles[0]?.name == 'agency_user' && !facilityList?.length) {
            const facility = shift?.shift?.map(item => {
                return { ...item.facility_id }
            })
            const uuniqueList = uniqueArrayOfObject(facility, '_id')
            const list = filterData(uuniqueList)
            setFacilityList(sortArrayByValue(list))
        }
        else if (profile?.data?.roles[0]?.name == 'normal' && !facilityList?.length) {
            const list = filterData(profile?.data?.facility)
            setFacilityList(sortArrayByValue(list))
        }
        else {
            const list = filterData(facility?.facility_data);
            setFacilityList(sortArrayByValue(list))
        }
    }, [profile, facility])


    useEffect(() => {
        if (patientsWithContacts) {
            const initialOpenState = patientsWithContacts?.reduce((acc, row) => {
                acc[`${row?._id}`] = false;
                return acc;
            }, {});
            setOpen(initialOpenState);
        }
    }, [patientsWithContacts]);

    const lastElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPage(prevPage => prevPage + 1);
            }
        });

        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    const handalFilter = (searchFilter, page) => {
        return {
            startdate: searchFilter?.startdate ? searchFilter?.startdate : '',
            enddate: searchFilter?.enddate ? searchFilter?.enddate : '',
            disStartdate: searchFilter?.disStartdate ? searchFilter?.disStartdate : '',
            disEnddate: searchFilter?.disEnddate ? searchFilter?.disEnddate : '',
            facility: searchFilter?.facility ? searchFilter?.facility : '',
            selectedRelations: searchFilter?.selectedRelations ? searchFilter?.selectedRelations : '',
            selectedContact: searchFilter?.selectedContact ? searchFilter?.selectedContact : '',
            active: searchFilter?.active ? searchFilter?.active : '',
            page: page + 1,
            limit: 50
        }
    }
    const handleChange = (event, newValue) => {
        setSelectedUnit(newValue);
    };




    useEffect(() => {
        if (debouncedSearch.length > 2 || debouncedSearch === '') {
            setPage(0);
            console.log("page set to 0");
            const filterization = handalFilter(searchFilter, 0);
            dispatch(get_patient_with_contacts({
                token,
                search: debouncedSearch,
                ...filterization,
                selectedRelations: selectedRelations,
                selectedContact: selectedContact,
                selectedUnit: selectedUnit,
                phone: debouncedPhone,
                contact_name: debouncedContactName,
                user: profile?.data,
                active: active
            })).then((result) => {
                if (!result.payload?.data?.length || result.payload?.data?.length < 50) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                }
            });
            // for contact counts---
            dispatch(getPatientAndContactCounts({
                token,
                search: debouncedSearch,
                ...filterization,
                selectedRelations: selectedRelations,
                selectedContact: selectedContact,
                selectedUnit: selectedUnit,
                phone: debouncedPhone,
                contact_name: debouncedContactName,
                user: profile?.data,
                active: active
            }))
        }
    }, [debouncedSearch, token, searchFilter, debouncedRelationship, debouncedPhone, selectedContact, debouncedContactName, selectedUnit]); // Removed page from dependencies

    // Add page-specific useEffect
    useEffect(() => {
        if (page > 0) { // Only fetch for pages after the first
            const filterization = handalFilter(searchFilter, page);
            dispatch(get_patient_with_contacts({
                token,
                search: debouncedSearch,
                ...filterization,
                selectedRelations: selectedRelations,
                selectedContact: selectedContact,
                selectedUnit: selectedUnit,
                phone: debouncedPhone,
                contact_name: debouncedContactName,
                user: profile?.data,
                active: active
            })).then((result) => {
                if (!result.payload?.data?.length || result.payload?.data?.length < 50) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                }
            });
        }
    }, [page]);

    // date handle
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    });
    // date handle
    const [dischargeRange, setDischargeRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    });



    // handal Date 
    const handleDate = (ranges) => {
        const End = new Date(
            new Date(ranges.selection.endDate).getTime() + 86400000
        );
        const stdate = new Date(ranges.selection.startDate);
        const endate = new Date(End);
        setSelectionRange(ranges.selection);
        setSearchFilter({
            ...searchFilter,
            startdate: moment(stdate).format("YYYY-MM-DD"),
            enddate: moment(endate).format("YYYY-MM-DD")
        })
    };

    // handleDischargeDate
    const handleDischargeDate = (ranges) => {
        const End = new Date(
            new Date(ranges.selection.endDate).getTime() + 86400000
        );
        const stdate = new Date(ranges.selection.startDate);
        const endate = new Date(End);
        setDischargeRange(ranges.selection);
        setSearchFilter({
            ...searchFilter,
            disStartdate: moment(stdate).format("YYYY-MM-DD"),
            disEnddate: moment(endate).format("YYYY-MM-DD")
        })
    };
    const handleContactChange = (_, value) => {
        const contactId = value[value.length - 1]?._id;
        const isSelected = selectedContact.some((contact) => contact._id === contactId);
        if (isSelected) {
            const updatedContact = selectedContact.filter(contact => contact._id !== contactId);
            setSelectedcontact(updatedContact);
        } else {
            setSelectedcontact(value);
        }
    };


    const handleRelationChange = (_, value) => {
        const relationId = value[value.length - 1]?._id;
        const isSelected = selectedRelations.some((relation) => relation._id === relationId);
        if (isSelected) {
            const updatedRelations = selectedRelations.filter(relation => relation._id !== relationId);
            setSelectedRelations(updatedRelations);
        } else {
            setSelectedRelations(value);
        }
    };



    const handalSearchById = (name, value) => {
        setSearchFilter({
            ...searchFilter,
            [name]: value
        })
    }

    const handalSearchBydischargedate = (discharge_date, value) => {
        setSearchFilter({
            ...searchFilter,
            [discharge_date]: value
        })
    }


    //sr20
    const sendBroadcast = async () => {
        setIsLoading(true);
        try {
            if (!broadcastMessage.trim()) {
                toast.error("Message and numbers are required");
                return;
            }
            if (totalContacts > (process.env.REACT_APP_CONTACT_LIMIT_FOR_MESSAGES || 500)) {
                setShowlimit(true);
                return;
            }

            const filterization = handalFilter(searchFilter, 0);
            const res = await dispatch(create_brodcast_message({
                token,
                message: broadcastMessage,
                search: debouncedSearch,
                ...filterization,
                selectedRelations: selectedRelations,
                selectedContact: selectedContact,
                selectedUnit: selectedUnit,
                phone: debouncedPhone,
                contact_name: debouncedContactName,
                user: profile?.data,
                active: active,
                filters: {
                    patient_name: searchQuery,
                    ...searchFilter,
                    active: active ? active : false
                },
            }))
            if (res?.payload?.status != 400) {
                setTextResponse(res?.payload);
                setBroadcastmessage("");
                setShow(true);
            }else{
                console.log("APIresponse" , res?.payload?.data?.msg);
                setShowconfirm(false);
                toast.error("Something went wrong");
            }
        } catch (error) {
            console.log("Sending Error", error)
        } finally {
            console.log("Function finished");
            setIsLoading(false);
        }
    }

    const handelConfirm = async () => {
        // console.log("process.env.REACT_APP_CONTACT_LIMIT_FOR_MESSAGES ", process.env , process.env.REACT_APP_CONTACT_LIMIT_FOR_MESSAGES , (process.env.REACT_APP_CONTACT_LIMIT_FOR_MESSAGES || 500) )
        if (totalContacts > (process.env.REACT_APP_CONTACT_LIMIT_FOR_MESSAGES || 500)) {
            setShowlimit(true);
            return;
        }else{
            setShowconfirm(true)
        }
    }

    function handalClose() {
        setShow(false);
        setShowconfirm(false)
        setShowlimit(false)
        setTextResponse(null);
    }
    function handleRiderect() {
        navigate(`/broadcast/${textResponse?.broadcast?._id}`);
        setTextResponse(null);
    }
    return (
        <>
            <div className="contact_main_div ">
                <Sidebar />
                <div className="head-div maindiv">
                    <div className="head_header mainheader">
                        <h2 ><FaArrowLeft size={30} style={{
                            marginRight: "6px",
                            cursor: "pointer",
                        }} onClick={() => {
                            navigate(-1);
                        }} />  Mass Broadcast List</h2>
                        <div className="admin-section adminname">
                            {/* <p><i className="fa-regular fa-bell"></i></p>
                            <div>
                                <img src={Ellipse113} alt="" />
                            </div> */}
                            {/* <div className="bdge-auth">
                                <p>{profile?.data?.first_name?.substring(0, 1).toUpperCase()}</p>
                            </div> */}
                            <span> {profile?.status ? profile?.data?.first_name + " " + profile?.data?.last_name : ""}</span>
                        </div>
                    </div>
                    <div className='filer_boxs '>
                        <div className='filer_box' style={{
                            padding: '6px',
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center"
                        }}>
                            <div className='filterheader' style={{
                                width: "100%",
                                display: "flex",
                                padding: "0px 6px",
                                justifyContent: "space-between"
                            }} >
                                <p>Apply filters</p>

                                <div className="date-fltr clearfilter" style={{
                                    cursor: "pointer"
                                }} onClick={() => {
                                    setSearchFilter('');
                                    setSearchQuery('');
                                    setSelectedRelations([]);
                                    setPhone('');
                                    setContactName('');
                                    setSelectedcontact([]);
                                    setSelectedUnit(null);
                                    setPage(0);
                                    setActive(false);

                                }}>
                                    <span>
                                        <i className='px-1' style={{ color: "#4C7153" }}><PiArrowClockwiseBold /></i>
                                        clear filter
                                    </span>
                                </div>
                            </div>
                            <div className="px-2 py-2 d-flex gap-3 firstfield">
                                <TextField id="outlined-basic" label="Search patient name" variant="outlined" size="small" sx={{
                                    width: "50%"
                                }}
                                    value={searchQuery}
                                    onChange={(e) => {
                                        setSearchQuery(e.target.value)
                                    }}
                                />
                                <Autocomplete
                                    multiple
                                    id="size-small-filled"
                                    size="small"
                                    options={
                                        facilityList?.length
                                            ? [{ _id: "select-all", value: "Select All" }, ...facilityList]
                                            : [listInit]
                                    }
                                    sx={{ width: "50%" }}
                                    value={searchFilter?.facility || []}
                                    getOptionLabel={(option) => option?.value}
                                    onChange={(_, value) => {
                                        if (value.some((item) => item._id === "select-all")) {
                                            const allFacilities = facilityList;
                                            handalSearchById("facility", allFacilities);
                                        } else {
                                            handalSearchById("facility", value);
                                        }
                                    }}
                                    open={isDropdown}
                                    onOpen={() => setIsDropdown(true)}
                                    onClose={() => setIsDropdown(false)}
                                    renderOption={(props, option, { selected }) => (
                                        <li
                                            {...props}
                                            style={{
                                                cursor: "pointer",
                                                backgroundColor: selected ? "#345d3b" : "transparent",
                                                color: selected ? "white" : "black",
                                            }}
                                            key={option._id}
                                            className="py-1 ps-3 menu-drop-item"
                                        >
                                            {option.value}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <Tooltip
                                            title={
                                                isDropdown
                                                    ? ""
                                                    : searchFilter?.facility?.length > 0
                                                        ? searchFilter.facility
                                                            .map((facility) => facility.value)
                                                            .join(", ")
                                                        : "No facility selected"
                                            }
                                        >
                                            <TextField
                                                {...params}
                                                label="Facility"
                                                placeholder="Select Facilities"
                                                sx={{ maxWidth: "100%" }}
                                            />
                                        </Tooltip>
                                    )}
                                    renderTags={(value, getTagProps) => {
                                        if (value.length > 0) {
                                            return (
                                                <>
                                                    <Chip
                                                        sx={{
                                                            maxWidth: "80px",
                                                            width: "80px",
                                                            maxHeight: "25px",
                                                            height: "18px",
                                                            "& .MuiChip-deleteIcon": {
                                                                fontSize: "15px",
                                                            },
                                                        }}
                                                        key={value[0]._id}
                                                        label={value[0].value}
                                                        {...getTagProps({ index: 0 })}
                                                        onDelete={() =>
                                                            handalSearchById(
                                                                "facility",
                                                                value.filter((_, i) => i !== 0)
                                                            )
                                                        }
                                                    />
                                                    {value.length > 1 ? "..." : ""}
                                                </>
                                            );
                                        }
                                        return null;
                                    }}
                                />
                            </div>

                            <div className='px-2 py-2 d-flex gap-3 firstfield'>
                                <Autocomplete
                                    multiple
                                    id="size-small-filled"
                                    size="small"
                                    options={[{ _id: "select-all", value: "Select All" }, ...(contactOption?.slice().sort((a, b) => a.value.localeCompare(b.value)) || [listInit])]}
                                    sx={{ width: "50%" }}
                                    value={selectedContact || []}
                                    getOptionLabel={(option) => option?.value}
                                    onChange={(_, value) => {
                                        const isSelectAllSelected = value.some((option) => option._id === "select-all");

                                        if (isSelectAllSelected) {
                                            handleContactChange(_, contactOption || []);
                                        } else {
                                            handleContactChange(_, value);
                                        }
                                    }}
                                    open={dropdownopen}
                                    onOpen={() => setDropdownopen(true)}
                                    onClose={() => setDropdownopen(false)}
                                    renderOption={(props, option) => {
                                        const isSelected = selectedContact.some((contact) => contact._id === option._id);
                                        return (
                                            <li
                                                {...props}
                                                style={{
                                                    cursor: "pointer",
                                                    backgroundColor: isSelected ? "#345d3b" : "transparent",
                                                    color: isSelected ? "white" : "black",
                                                }}
                                                key={option._id}
                                                className="py-1 ps-3 menu-drop-item"
                                            >
                                                {option.value}
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <Tooltip
                                            title={
                                                isDropdownOpen
                                                    ? ""
                                                    : selectedContact.length > 0
                                                        ? selectedContact.map((contact) => contact.value).join(", ")
                                                        : "No contact type selected"
                                            }
                                            arrow
                                        >
                                            <TextField
                                                {...params}
                                                label="Contact Type"
                                                placeholder="Select Contact Type"
                                                InputLabelProps={{ shrink: dropdownopen || selectedContact.length > 0 }}
                                                sx={{ maxWidth: "100%" }}
                                            />
                                        </Tooltip>
                                    )}
                                    renderTags={(value, getTagProps) => {
                                        const displayCount = 1; // Number of chips to show
                                        if (value.length > displayCount) {
                                            return (
                                                <>
                                                    {value.slice(0, displayCount).map((item, index) => (
                                                        <Chip
                                                            sx={{
                                                                maxWidth: "80px",
                                                                width: "80px",
                                                                maxHeight: "25px",
                                                                height: "18px",
                                                                "& .MuiChip-deleteIcon": {
                                                                    fontSize: "15px",
                                                                },
                                                            }}
                                                            key={item._id}
                                                            label={item.value || "Unknown"}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))}
                                                    <Chip
                                                        label={`+${value.length - displayCount} more`}
                                                        sx={{
                                                            maxWidth: "80px",
                                                            width: "80px",
                                                            maxHeight: "25px",
                                                            height: "18px",
                                                            "& .MuiChip-deleteIcon": {
                                                                fontSize: "15px",
                                                            },
                                                        }}
                                                    />
                                                </>
                                            );
                                        }
                                        return value.map((item, index) => (
                                            <Chip
                                                sx={{
                                                    maxWidth: "80px",
                                                    width: "80px",
                                                    maxHeight: "25px",
                                                    height: "18px",
                                                    "& .MuiChip-deleteIcon": {
                                                        fontSize: "15px",
                                                    },
                                                }}
                                                key={item._id}
                                                label={item.value || "Unknown"}
                                                {...getTagProps({ index })}
                                            />
                                        ));
                                    }}
                                />

                                <Autocomplete
                                    multiple
                                    id="size-small-filled"
                                    size="small"
                                    options={[
                                        { _id: "select-all", value: "Select All" },
                                        ...(relationsOptions
                                            ? relationsOptions?.slice().sort((a, b) => a.value.localeCompare(b.value))
                                            : [listInit]),
                                        { _id: "unknown", value: "Unknown" },
                                    ]}
                                    sx={{ width: "50%" }}
                                    value={selectedRelations || []}
                                    getOptionLabel={(option) => option?.value}
                                    onChange={(_, value) => {
                                        const isSelectAllSelected = value.some((option) => option._id === "select-all");
                                        const isUnknownSelected = value.some((option) => option._id === "unknown");

                                        if (isSelectAllSelected) {
                                            const updatedValue = [...(relationsOptions || []), { _id: "unknown", value: "" }];
                                            handleRelationChange(_, updatedValue);
                                        } else if (isUnknownSelected) {
                                            handleRelationChange(_, [{ _id: "unknown", value: "" }]);
                                        } else {
                                            handleRelationChange(_, value);
                                        }
                                    }}
                                    open={isDropdownOpen}
                                    onOpen={() => setIsDropdownOpen(true)}
                                    onClose={() => setIsDropdownOpen(false)}
                                    renderOption={(props, option) => {
                                        const isSelected = selectedRelations.some((relation) => relation._id === option._id);
                                        return (
                                            <li
                                                {...props}
                                                style={{
                                                    cursor: "pointer",
                                                    backgroundColor: isSelected ? "#345d3b" : "transparent",
                                                    color: isSelected ? "white" : "black",
                                                }}
                                                key={option._id}
                                                className="py-1 ps-3 menu-drop-item"
                                            >
                                                {option.value}
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <Tooltip
                                            title={
                                                isDropdownOpen
                                                    ? ""
                                                    : selectedRelations.length > 0
                                                        ? selectedRelations.map((relation) => relation.value).join(", ")
                                                        : "No relation selected"
                                            }
                                            arrow
                                        >
                                            <TextField
                                                {...params}
                                                label="Family Relations"
                                                placeholder="Select Relations"
                                                InputLabelProps={{ shrink: isDropdownOpen || selectedRelations.length > 0 }}
                                                sx={{ maxWidth: "100%" }}
                                            />
                                        </Tooltip>
                                    )}
                                    renderTags={(value, getTagProps) => {
                                        const displayCount = 1;
                                        if (value.length > displayCount) {
                                            return (
                                                <>
                                                    {value.slice(0, displayCount).map((item, index) => (
                                                        <Chip
                                                            sx={{
                                                                maxWidth: "80px",
                                                                width: "80px",
                                                                maxHeight: "25px",
                                                                height: "18px",
                                                                "& .MuiChip-deleteIcon": {
                                                                    fontSize: "15px",
                                                                },
                                                            }}
                                                            key={item._id}
                                                            label={item.value || "Unknown"}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))}
                                                    <Chip
                                                        label={`+${value.length - displayCount} more`}
                                                        sx={{
                                                            maxWidth: "80px",
                                                            width: "80px",
                                                            maxHeight: "25px",
                                                            height: "18px",
                                                            "& .MuiChip-deleteIcon": {
                                                                fontSize: "15px",
                                                            },
                                                        }}
                                                    />
                                                </>
                                            );
                                        }
                                        return value.map((item, index) => (
                                            <Chip
                                                sx={{
                                                    maxWidth: "80px",
                                                    width: "80px",
                                                    maxHeight: "25px",
                                                    height: "18px",
                                                    "& .MuiChip-deleteIcon": {
                                                        fontSize: "15px",
                                                    },
                                                }}
                                                key={item._id}
                                                label={item.value || "Unknown"}
                                                {...getTagProps({ index })}
                                            />
                                        ));
                                    }}
                                />


                                {/* <div className='w-100'>
                                    <div className="Admission_date">
                                        <span className='d-flex justify-content-lg-start align-items-center'>
                                            <MdCalendarMonth />
                                            {searchFilter?.disStartdate && searchFilter?.disEnddate ? (
                                                <span className="date-icon-span">
                                                    {searchFilter?.disStartdate} / {searchFilter?.disEnddate}
                                                </span>
                                            ) : (
                                                <span className="date-icon-span fs-6">Discharge Date</span>
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-xl-3  col-lg-4 col-md-4 col-sm-6 mt-2 mt-sm-0">
                                        <Dropdown className="dash-main-filter">
                                            <Dropdown.Toggle style={{ height: "100%", width: "100%", }} ></Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <DateRange ranges={[dischargeRange]} onChange={handleDischargeDate} />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div> */}
                            </div>
                            <div className='p-2 d-flex gap-3 align-content-center gap-4 firstfield' style={{
                                width: "100%",
                            }} >

                                <Select

                                    value={active ? "Active" : "Inactive"}
                                    onChange={(event) => {
                                        const isActive = event.target.value === "Active";
                                        setActive(isActive);
                                        handalSearchBydischargedate(isActive);
                                    }}
                                    sx={{
                                        width: "100%",
                                        height: "40px",
                                        color: active ? 'black' : 'black',
                                        backgroundColor: active ? 'light' : 'white',
                                        '& .MuiSelect-icon': {
                                            color: active ? 'gray' : 'gray',
                                        },
                                    }}
                                >

                                    <MenuItem value="Active" sx={{}}>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <AiOutlineUserDelete size={14} />
                                            <span>Inactive</span>
                                        </Box>
                                    </MenuItem>

                                    <MenuItem value="Inactive" sx={{ height: 36 }}>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <AiOutlineUserAdd size={14} />
                                            <span>Active</span>
                                        </Box>
                                    </MenuItem>
                                </Select>
                                <div className=' w-100'>
                                    <div className="Admission_date">
                                        <span className='d-flex justify-content-lg-start align-items-center gap-2'>
                                            <MdCalendarMonth />
                                            {searchFilter?.startdate && searchFilter?.enddate ? (
                                                <span className="date-icon-span">
                                                    {searchFilter?.startdate} / {searchFilter?.enddate}
                                                </span>
                                            ) : (
                                                <span className="date-icon-span fs-6">Admission Date</span>
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-xl-3  col-lg-4 col-md-4 col-sm-6 mt-2 mt-sm-0">
                                        <Dropdown className="dash-main-filter">
                                            <Dropdown.Toggle style={{ height: "100%", width: "100%", }} ></Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <DateRange ranges={[selectionRange]} onChange={handleDate} />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>

                            </div>
                            <div className='p-2 d-flex align-content-center ' style={{
                                width: "97%",
                            }}>
                                <Autocomplete
                                    multiple
                                    id="unit"
                                    size="small"
                                    options={[{ value: "select-all" }, ...unitOptions?.slice().sort((a, b) => a.value.localeCompare(b.value)) || [listInit]]}
                                    sx={{ width: "50%" }}
                                    value={selectedUnit || []}
                                    getOptionLabel={(option) => option?.value}
                                    onChange={(_, value) => {
                                        const isSelectAllSelected = value.some((option) => option?.value === "select-all");

                                        if (isSelectAllSelected) {
                                            handleChange(_, unitOptions || []);
                                        } else {
                                            handleChange(_, value);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <Tooltip
                                            title={
                                                selectedUnit?.length > 0
                                                    ? selectedUnit?.map((relation) => relation.value).join(", ")
                                                    : "No unit selected"
                                            }
                                            arrow
                                        >
                                            <TextField
                                                {...params}
                                                label="Select Unit"
                                                variant="outlined"
                                            />
                                        </Tooltip>
                                    )}
                                    renderTags={(value, getTagProps) => {
                                        const displayCount = 1; // Number of chips to show
                                        if (value.length > displayCount) {
                                            return (
                                                <>
                                                    {value.slice(0, displayCount).map((item, index) => (
                                                        <Chip
                                                            sx={{
                                                                maxWidth: "80px",
                                                                width: "80px",
                                                                maxHeight: "25px",
                                                                height: "18px",
                                                                "& .MuiChip-deleteIcon": {
                                                                    fontSize: "15px",
                                                                },
                                                            }}
                                                            key={item._id}
                                                            label={item.value || "Unknown"}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))}
                                                    <Chip
                                                        label={`+${value.length - displayCount} more`}
                                                        sx={{
                                                            maxWidth: "80px",
                                                            width: "80px",
                                                            maxHeight: "25px",
                                                            height: "18px",
                                                            "& .MuiChip-deleteIcon": {
                                                                fontSize: "15px",
                                                            },
                                                        }}
                                                    />
                                                </>
                                            );
                                        }
                                        return value.map((item, index) => (
                                            <Chip
                                                sx={{
                                                    maxWidth: "80px",
                                                    width: "80px",
                                                    maxHeight: "25px",
                                                    height: "18px",
                                                    "& .MuiChip-deleteIcon": {
                                                        fontSize: "15px",
                                                    },
                                                }}
                                                key={item._id}
                                                label={item.value || "Unknown"}
                                                {...getTagProps({ index })}
                                            />
                                        ));
                                    }}
                                />

                            </div>

                        </div>

                        <div
                            className='filer_box'
                            style={{
                                padding: '14px',
                                flexDirection: "column",
                                justifyContent: "center"
                            }}
                        >
                            <p>Broadcast Message</p>
                            <textarea
                                rows={7}
                                placeholder='Write your message here...'
                                value={broadcastMessage}
                                onChange={(e) => setBroadcastmessage(e.target.value)}
                                disabled={!(patientsWithContacts?.length > 0)}
                            />
                            <div className='filter_send'>
                                <button
                                    disabled={
                                        msgLoading ||
                                        !(patientsWithContacts?.length > 0) ||
                                        !broadcastMessage.trim()
                                    }
                                    onClick={handelConfirm}
                                    style={{
                                        backgroundColor: !(patientsWithContacts?.length > 0) || !broadcastMessage.trim() ? "gray" : ""
                                    }}
                                >
                                    Send
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="w-100  p-3">
                        <p className="mb-2 fw-bold">
                            Total patients included in the broadcast:
                            <span className="fw-bold px-2">
                                {!countLoading ? totalPatientsWithContacts || 0 : "..."}
                            </span>
                        </p>
                        <p className="mb-0 fw-bold">
                            Total contacts included in the broadcast:
                            <span className="fw-bold px-2">
                                {!countLoading ? totalContacts || 0 : "..."}
                            </span>
                        </p>
                    </div>




                    <div className='filter_table'>
                        <TableContainer component={Paper} sx={{ borderRadius: "15px" }}>
                            {!loading ? (
                                <>
                                    <Table sx={{ minWidth: 650 }}>
                                        {patientsWithContacts?.length > 0 ? (
                                            <>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left" sx={{ fontWeight: 700 }}>Name</TableCell>
                                                        <TableCell sx={{ fontWeight: 700 }} align="left">Facility</TableCell>
                                                        <TableCell sx={{ fontWeight: 700 }} align="left">Unit</TableCell>
                                                        <TableCell align="left" sx={{ fontWeight: 700 }}>Admission Date</TableCell>
                                                        <TableCell className='' align="left" sx={{ fontWeight: 700 }}>Discharge Date</TableCell>
                                                        <TableCell />
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {patientsWithContacts?.map((row, index) => {
                                                        const patientName = `${row?.first_name} ${row?.last_name}`;
                                                        const isLastElement = patientsWithContacts.length === index + 1;
                                                        return (
                                                            <React.Fragment key={row._id}>
                                                                <TableRow ref={isLastElement ? lastElementRef : null} >
                                                                    <TableCell align="left">{patientName}</TableCell>
                                                                    <TableCell align="left">{row?.facility?.name || "N/A"}</TableCell>
                                                                    <TableCell align="left"> {row?.unit || "N/A"}</TableCell>
                                                                    <TableCell align="left">
                                                                        {row?.createdAt ? moment(row?.createdAt).utc().format('MM/DD/YYYY h:mm A') : "N/A"}
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        {row?.discharge_date ? moment(row?.discharge_date).utc().format('MM/DD/YYYY h:mm A') : "N/A"}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {row?.contacts?.length > 0 &&
                                                                            <IconButton
                                                                                aria-label="expand row"
                                                                                size="small"
                                                                                onClick={() => {
                                                                                    handleRowClick(row?._id)
                                                                                }}>
                                                                                {open[row?._id] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                                                            </IconButton>}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell style={{ padding: 0 }} colSpan={7}>
                                                                        {row?.contacts?.length > 0 &&
                                                                            <Collapse in={open[row._id]} timeout="auto" unmountOnExit>
                                                                                <Box margin={0}>
                                                                                    <Table size="small" aria-label="contacts" sx={{ backgroundColor: "#0000001a" }}>
                                                                                        <TableHead>
                                                                                            <TableRow >
                                                                                                <TableCell sx={{ fontWeight: 700, color: "gray" }} >Contact Name</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 700, color: "gray" }} align="left">Contact Type</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 700, color: "gray" }} align="left">Phone</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 700, color: "gray" }} align="left">Relationship</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 700, color: "gray" }} align="left">Created At</TableCell>
                                                                                                <TableCell sx={{ fontWeight: 700, color: "gray" }} align="left"></TableCell>
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>
                                                                                            {row?.contacts?.length > 0 && (
                                                                                                <>
                                                                                                    {/* Visible Contacts */}
                                                                                                    {row?.contacts
                                                                                                        ?.filter(contact => contact?.phone && contact?.phone !== "\u0000")
                                                                                                        .map(contact => (
                                                                                                            <TableRow key={contact._id}>
                                                                                                                <TableCell>{contact.first_name} {contact.last_name}</TableCell>
                                                                                                                <TableCell align="left">{contact.contact_type || "N/A"}</TableCell>
                                                                                                                <TableCell align="left">{contact.phone}</TableCell>
                                                                                                                <TableCell align="left">{contact?.relationship || "Unknown"}</TableCell>
                                                                                                                <TableCell align="left">
                                                                                                                    {contact?.createdAt
                                                                                                                        ? moment(contact?.createdAt).utc().format("DD/MM/YYYY h:mm A")
                                                                                                                        : "N/A"}
                                                                                                                </TableCell>
                                                                                                            </TableRow>
                                                                                                        ))}

                                                                                                    {/* Button to toggle hidden contacts */}
                                                                                                    <TableRow>
                                                                                                        <TableCell colSpan={8} align="right">
                                                                                                            Ineligible Contacts
                                                                                                            <IconButton
                                                                                                                onClick={() => toggleHiddenContacts(row._id)}
                                                                                                                aria-label="toggle hidden contacts"
                                                                                                            >
                                                                                                                {hiddenContacts[row._id] ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                                                                            </IconButton>
                                                                                                        </TableCell>
                                                                                                    </TableRow>

                                                                                                    {/* Hidden Contacts */}
                                                                                                    {hiddenContacts[row._id] &&
                                                                                                        row?.contacts
                                                                                                            ?.filter(contact => !contact?.phone || contact?.phone === "\u0000")
                                                                                                            .map(contact => (
                                                                                                                <TableRow key={contact._id} sx={{ backgroundColor: "#DCDCDC" }}>
                                                                                                                    <TableCell>{contact.first_name} {contact.last_name}</TableCell>
                                                                                                                    <TableCell align="left">{contact.contact_type || "Unknown"}</TableCell>
                                                                                                                    <TableCell align="left">N/A</TableCell>
                                                                                                                    <TableCell align="left">{contact?.relationship || "N/A"}</TableCell>
                                                                                                                    <TableCell align="left">
                                                                                                                        {contact?.createdAt
                                                                                                                            ? moment(contact?.createdAt).utc().format("DD/MM/YYYY h:mm A")
                                                                                                                            : "N/A"}
                                                                                                                    </TableCell>
                                                                                                                </TableRow>
                                                                                                            ))}
                                                                                                </>
                                                                                            )}

                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </Box>
                                                                            </Collapse>}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </TableBody>
                                            </>
                                        ) : (
                                            <TableBody
                                                sx={{
                                                    height: "30vh",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    width: "100%",
                                                    fontWeight: 700,
                                                    fontSize: "24px"
                                                }}>
                                                No Data Found
                                            </TableBody>
                                        )}
                                    </Table>
                                </>
                            ) : (
                                <div>
                                    <div className="py-2 px-2">
                                        <Skeleton variant="rounded" height={36} />
                                    </div>
                                    <div className="d-flex justify-content-between  py-3 px-2">
                                        {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                    </div>
                                    <div className="d-flex justify-content-between  py-3 px-2">
                                        {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                    </div>
                                    <div className="d-flex justify-content-between  py-3 px-2">
                                        {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                    </div>
                                    <div className="d-flex justify-content-between  py-3 px-2">
                                        {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                    </div>
                                    <div className="d-flex justify-content-between  py-3 px-2">
                                        {columns?.map((it, key) => <Skeleton key={key} variant="rounded" height={26} width={120} />)}
                                    </div>
                                </div>
                            )}
                            {infiniteLoading && (
                                <Box sx={{ width: '100%', color: 'green' }}>
                                    <LinearProgress sx={{ backgroundColor: 'lightgreen', '& .MuiLinearProgress-bar': { backgroundColor: 'green' } }} />
                                </Box>
                            )}
                        </TableContainer>
                    </div>
                </div>
            </div>
            {/* <ToastContainer /> */}


            <Modal show={show} onHide={() => setShow(false)} centered>
                <Modal.Body>
                    <div className=' ' style={{ position: "absolute", top: "5px", right: "5px", cursor: 'pointer' }}><IoMdClose onClick={handalClose} size={25} /></div>
                    <div style={{ fontSize: '15px', color: '#3a503e' }} className="fw-bold py-4 text-center">
                        {/* Your message has been sent successfullly. <br /> */}

                        <TableContainer component={Paper} sx={{ borderRadius: "15px" }}>
                            <Table sx={{ width: 450 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" sx={{ fontWeight: 700 }}>Facility name</TableCell>
                                        <TableCell align="left" sx={{ fontWeight: 700 }}>Contact name</TableCell>
                                        <TableCell align="left" sx={{ fontWeight: 700 }}>Sent</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {textResponse?.received?.map((row) => (
                                        <TableRow key={row._id}>
                                            <TableCell align="left">{row?.facilityName || "N/A"}</TableCell>
                                            <TableCell align="left">{row?.name || "N/A"}</TableCell>
                                            <TableCell align="left"><MdOutlineVerified color='green' size={24} /></TableCell>

                                        </TableRow>
                                    ))}
                                    {textResponse?.failed?.map((row) => (
                                        <TableRow key={row._id}>
                                            <TableCell align="left">{row?.facilityName || "N/A"}</TableCell>
                                            <TableCell align="left">{row?.name || "N/A"}</TableCell>
                                            <TableCell align="left">
                                                <Tooltip title={row?.reason} placement='top'>
                                                    <span>
                                                        <MdOutlineDangerous color='red' size={24}/>
                                                    </span>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className="d-flex justify-content-center">
                        <button className="btn mx-1 btn-outline-success" onClick={handleRiderect}>Broadcast Summary</button>
                        {/* <button className="btn mx-1 btn-success" onClick={handalClose}>Okay</button> */}
                    </div>
                </Modal.Body>
            </Modal>

            {/* conferm modal  */}

            <Modal show={showconfirm} onHide={() => {
                setShowconfirm(false);
                setIsLoading(false);
            }} centered   >
                <Modal.Body >
                    <div className=' ' style={{ position: "absolute", top: "5px", right: "5px", cursor: 'pointer' }}><IoMdClose onClick={handalClose} size={25} /></div>
                    <div style={{ fontSize: '15px', color: '#3a503e' }} className="fw-bold py-4 text-center">
                        Are you sure you want to send message to {totalContacts} contacts
                    </div>
                    <div className="d-flex justify-content-center">
                        <button 
                        className="btn mx-1 btn-outline-success" 
                        onClick={sendBroadcast}
                        disabled={isLoading} 
                    >
                        {isLoading ? "Please wait..." : "Send"}
                    </button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* contact modal */}


            <Modal show={showlimit} onHide={() => setShowlimit(false)} centered>
                <Modal.Body>
                    <div className="d-flex justify-content-space-between align-items-center">
                        <div className="div"> <img src={warningIcon} alt="Warning" style={{
                            height:"30px" , width:"30px"
                        }} /> <b>Warning </b></div>
                        <div className=' ' style={{ position: "absolute", top: "5px", right: "5px", cursor: 'pointer' }}><IoMdClose onClick={handalClose} size={25} /></div></div>
                    <div style={{ fontSize: '15px', color: '#3a503e' }} className="fw-bold py-4 text-center">
                        You cannot send message to more than {process.env.REACT_APP_CONTACT_LIMIT_FOR_MESSAGES || 500} contacts at a time.
                    </div>
                    <div className="d-flex justify-content-center">
                        <button className="btn mx-1 btn-outline-success" onClick={handalClose}>Okay</button>
                        {/* <button className="btn mx-1 btn-success" onClick={handalClose}>Okay</button> */}
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}
export default Mass
